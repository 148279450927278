.kt-spinner {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-size: 0.7rem;
  height: 50%;
}

.kt-spinner div {
  margin: 1rem 0;
}