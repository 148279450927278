.kt-alert-wrapper {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
}

.kt-alert {
  display: none;
  opacity: 0;
  padding: 0.6rem 1rem;
  color: #fff;
  font-family: 'Oxanium';
  font-size: 0.8rem;
  text-align: center;
  animation: fadeInOut 3s;
}

.kt-alert--show {
  display: flex;
}

.kt-alert--error {
  background: rgb(222,82,29);
  background: linear-gradient(180deg, rgba(222,82,29,1) 0%, rgba(198,81,36,1) 100%);
  border: 1px solid #581845;
}
.kt-alert--warning {
  color: #252525;
  background: rgb(255,249,118);
  background: linear-gradient(180deg, rgba(255,249,118,1) 0%, rgba(255,244,5,1) 100%);
  border: 1px solid #C6A224;
}
.kt-alert--info {
  background: rgb(42,221,181);
  background: linear-gradient(180deg, rgba(42,221,181,1) 0%, rgba(36,198,162,1) 100%);
  border: 1px solid #24C651;
}
.kt-alert--success {
  background: rgb(41,217,90);
  background: linear-gradient(180deg, rgba(41,217,90,1) 0%, rgba(36,198,81,1) 100%);
  border: 1px solid #24C651;
}

.kt-alert-text-left {
  text-align: left;
}

@keyframes fadeInOut {
  0% {
      opacity: 0;
  }
  16% {
     opacity: 1;
  }
  84% {
     opacity: 1;
  }
  100% {
     opacity: 0;
  }
}